import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toPng } from "html-to-image";
import "./OfferForm.css";

const OfferForm = () => {
  const [formData, setFormData] = useState({
    bedrooms: "",
    bathrooms: "",
    ptype: "",
    barea: "",
    parea: "",
    furnished: "",
    size: "",
    price: "",
    rera: "",
    logo: "https://i.imgur.com/EPu5Tos.png",
    headimgs: [],
    status: "",
    amenities: [],
    paymentPlan: [{ description: "", amount: "" }],
    gallery: [],
    layouts: [{ title: "", description: "", image: "" }],
    agentName: "",
    agentPosition: "",
    agentPhone: "",
    agentEmail: "",
    agentImage: "",
    agentLinkedIn: "",
    agentFacebook: "",
    agentInstagram: "",
    locationLink: "", // Updated field for location link
    locationImage: "", // Base64 encoded image of the location
  });

  const navigate = useNavigate();
  const locationRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePaymentPlanChange = (index, e) => {
    const { name, value } = e.target;
    const paymentPlan = [...formData.paymentPlan];
    paymentPlan[index] = { ...paymentPlan[index], [name]: value };
    setFormData({ ...formData, paymentPlan });
  };

  const addPaymentPlan = () => {
    setFormData({
      ...formData,
      paymentPlan: [...formData.paymentPlan, { description: "", amount: "" }],
    });
  };

  const handleAmenitiesChange = (selectedOptions) => {
    setFormData({
      ...formData,
      amenities: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    });
  };

  const handleFurnishedChange = (selectedOption) => {
    setFormData({
      ...formData,
      furnished: selectedOption ? selectedOption.value : "",
    });
  };

  const handlePtypeChange = (selectedOption) => {
    setFormData({
      ...formData,
      ptype: selectedOption ? selectedOption.value : "",
    });
  };

  const handleStatusChange = (selectedOption) => {
    setFormData({
      ...formData,
      status: selectedOption ? selectedOption.value : "",
    });
  };

  const handleGalleryChange = (e) => {
    const files = e.target.files;
    const galleryFiles = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setFormData({ ...formData, gallery: galleryFiles });
  };

  const handleLayoutsChange = (index, e) => {
    const { name, value } = e.target;
    const layouts = [...formData.layouts];
    layouts[index] = { ...layouts[index], [name]: value };
    setFormData({ ...formData, layouts });
  };

  const handleLayoutImageChange = (index, e) => {
    const file = e.target.files[0];
    const layoutImage = URL.createObjectURL(file);
    const layouts = [...formData.layouts];
    layouts[index] = { ...layouts[index], image: layoutImage };
    setFormData({ ...formData, layouts });
  };

  const addLayout = () => {
    setFormData({
      ...formData,
      layouts: [...formData.layouts, { title: "", description: "", image: "" }],
    });
  };

  const handleImgChange = (e) => {
    const files = e.target.files;
    const headimgFiles = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setFormData({ ...formData, headimgs: headimgFiles });
  };

  const handleAgentImageChange = (e) => {
    const file = e.target.files[0];
    const agentImage = URL.createObjectURL(file);
    setFormData({ ...formData, agentImage });
  };

  const handleLocationImage = async () => {
    if (locationRef.current) {
      const dataUrl = await toPng(locationRef.current);
      setFormData({ ...formData, locationImage: dataUrl });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleLocationImage(); // Convert location link to image before submitting
    navigate("/offer-details", { state: { formData } });
  };

  const amenitiesOptions = [
    { value: "Balcony", label: "Balcony" },
    { value: "Central air conditioning", label: "Central air conditioning" },
    { value: "Covered parking", label: "Covered parking" },
    { value: "BBQ area", label: "BBQ area" },
    { value: "Retail Facilities", label: "Retail Facilities" },
    { value: "Entertainment Facilities", label: "Entertainment Facilities" },
    { value: "School", label: "School" },
    { value: "Golf Course", label: "Golf Course" },
    {
      value: "Laundry & Dry-Cleaning Services",
      label: "Laundry & Dry-Cleaning Services",
    },
    { value: "Fitness Centre", label: "Fitness Centre" },
    { value: "Gated Community", label: "Gated Community" },
    { value: "Children Nursery", label: "Children Nursery" },
    { value: "SPA", label: "SPA" },
    { value: "Pets Allowed", label: "Pets Allowed" },
    { value: "Hospital", label: "Hospital" },
    { value: "Bank/ATM Facility", label: "Bank/ATM Facility" },
    { value: "Basketball Courts", label: "Basketball Courts" },
    { value: "Beach Access", label: "Beach Access" },
    { value: "Bus services", label: "Bus services" },
    { value: "Business Centre", label: "Business Centre" },
    { value: "Cycling tracks", label: "Cycling tracks" },
    { value: "Metro station", label: "Metro station" },
    { value: "Floating Cinema", label: "Floating Cinema" },
    { value: "Outdoor Cinema", label: "Outdoor Cinema" },
    { value: "Landscaped Gardens", label: "Landscaped Gardens" },
    { value: "Barbecue Area ", label: "Barbecue Area " },
    { value: "Lounge ", label: "Lounge " },
    { value: "Security ", label: "Security " },
    { value: "GYM ", label: "GYM " },
    { value: "Mosque  ", label: "Mosque  " },
    { value: "Sport courts ", label: "Sport courts " },
    { value: "Tennis courts ", label: "Tennis courts " },
    { value: "Kids play area ", label: "Kids play area" },
    { value: "Waterfall ", label: "Waterfall " },
    { value: "Beauty bar ", label: "Beauty bar " },
    { value: "Jacuzzi ", label: "Jacuzzi " },
    { value: "Table-tennis ", label: "Table-tennis " },
    { value: "Smart-home ", label: "Smart-home " },
    { value: "Swimming pool ", label: "Swimming pool " },
  ];

  const furnishedOptions = [
    { value: "Furnished", label: "Furnished" },
    { value: "Unfurnished", label: "Unfurnished" },
  ];

  const ptypeOptions = [
    { value: "For Sale", label: "For Sale" },
    { value: "For Rent", label: "For Rent" },
  ];

  const statusOptions = [
    { value: "Offplan", label: "Offplan" },
    { value: "Ready to move in", label: "Ready to move in" },
  ];

  return (
    <div className="offer-form-container">
      <header
        className="header"
        style={{ background: "linear-gradient(#534936, #000000)" }}
      >
        <h1 style={{ textAlign: "left" }}>
          <img src="https://i.imgur.com/EPu5Tos.png" height="40px" alt="Logo" />
        </h1>
      </header>
      <form onSubmit={handleSubmit} className="offer-form">
        <h3>Image</h3>
        <input type="file" multiple onChange={handleImgChange} />
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          placeholder="Project Name"
        />
        <input
          type="text"
          name="rera"
          value={formData.rera}
          onChange={handleChange}
          placeholder="RERA Listings"
        />
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder="Address"
        />
        <input
          type="text"
          name="bedrooms"
          value={formData.bedrooms}
          onChange={handleChange}
          placeholder="Bedrooms"
        />
        <h3>Type</h3>
        <Select
          name="ptype"
          options={ptypeOptions}
          className="basic-single-select"
          classNamePrefix="select"
          onChange={handlePtypeChange}
        />
        <input
          type="text"
          name="bathrooms"
          value={formData.bathrooms}
          onChange={handleChange}
          placeholder="Bathrooms"
        />
        <input
          type="text"
          name="size"
          value={formData.size}
          onChange={handleChange}
          placeholder="Size (Sqft)"
        />
        <input
          type="text"
          name="barea"
          value={formData.barea}
          onChange={handleChange}
          placeholder="Parkings"
        />
        <input
          type="text"
          name="parea"
          value={formData.parea}
          onChange={handleChange}
          placeholder="Plot Area (Sqft)"
        />
        <h3>Furnished</h3>
        <Select
          name="furnished"
          options={furnishedOptions}
          className="basic-single-select"
          classNamePrefix="select"
          onChange={handleFurnishedChange}
        />
        <input
          type="text"
          name="price"
          value={formData.price}
          onChange={handleChange}
          placeholder="Price (AED)"
        />
        <h3>Status</h3>
        <Select
          name="status"
          options={statusOptions}
          className="basic-single-select"
          classNamePrefix="select"
          onChange={handleStatusChange}
        />
        <h3>Amenities</h3>
        <Select
          isMulti
          name="amenities"
          options={amenitiesOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleAmenitiesChange}
        />
        <h3>Payment Plan</h3>
        {formData.paymentPlan.map((item, index) => (
          <div key={index} className="payment-plan">
            <input
              type="text"
              name="description"
              value={item.description}
              onChange={(e) => handlePaymentPlanChange(index, e)}
              placeholder="Description"
            />
            <input
              type="text"
              name="amount"
              value={item.amount}
              onChange={(e) => handlePaymentPlanChange(index, e)}
              placeholder="Amount (AED)"
            />
          </div>
        ))}
        <button type="button" onClick={addPaymentPlan}>
          Add Payment Plan
        </button>
        <h3>Gallery</h3>
        <input type="file" multiple onChange={handleGalleryChange} />
        <h3>Layouts</h3>
        {formData.layouts.map((layout, index) => (
          <div key={index} className="layout-entry">
            <input
              type="text"
              name="title"
              value={layout.title}
              onChange={(e) => handleLayoutsChange(index, e)}
              placeholder="Title"
            />
            <input
              type="text"
              name="description"
              value={layout.description}
              onChange={(e) => handleLayoutsChange(index, e)}
              placeholder="Description"
            />
            <input
              type="file"
              name="image"
              onChange={(e) => handleLayoutImageChange(index, e)}
            />
          </div>
        ))}
        <button type="button" onClick={addLayout}>
          Add Layout
        </button>
        <h3>Agent Information</h3>
        <input
          type="text"
          name="agentName"
          placeholder="Agent Name"
          value={formData.agentName}
          onChange={handleChange}
        />
        <input
          type="text"
          name="agentPosition"
          placeholder="Agent Position"
          value={formData.agentPosition}
          onChange={handleChange}
        />
        <input
          type="text"
          name="agentPhone"
          placeholder="Agent Phone"
          value={formData.agentPhone}
          onChange={handleChange}
        />
        <input
          type="text"
          name="agentEmail"
          placeholder="Agent Email"
          value={formData.agentEmail}
          onChange={handleChange}
        />
        <input
          type="file"
          name="agentImage"
          onChange={handleAgentImageChange}
        />
        <h3>Location</h3>
        <input
          type="text"
          name="locationLink"
          placeholder="Location Link"
          value={formData.locationLink}
          onChange={handleChange}
        />
        <br />
        <div ref={locationRef} style={{ display: "none" }}>
          <iframe
            src={formData.locationLink}
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <button type="submit">Generate Sales Offer</button>
      </form>
    </div>
  );
};

export default OfferForm;
