import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import OfferForm from "./components/OfferForm";
import OfferDetails from "./components/OfferDetails";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<OfferForm />} />
          <Route path="/offer-details" element={<OfferDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
