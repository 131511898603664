import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import html2pdf from "html2pdf.js";
import "./OfferDetails.css";
import "bootstrap/dist/css/bootstrap.min.css";

const OfferDetails = () => {
  const location = useLocation();
  const { formData } = location.state || {};

  if (!formData) {
    return <div>No data available</div>;
  }

  const exportAsPDF = async () => {
    const opt = {
      margin: [0.01, 0.01, 0.01, 0.01],
      filename: `${formData.title}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { allowTaint: true, scale: 2, useCORS: true },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    const createPageContent = (bodyContent) => `
      <div class="main">  
          <div class="page" style="padding-left: 20px; padding-right: 20px; padding-top: 100px">
            <div class="pdf-body">
              ${bodyContent}
            </div>
          </div>
      </div>
    `;

    const firstPageContent = createPageContent(`
      <section>
        <div class="images">
          ${formData.headimgs
            .map(
              (headimg, index) =>
                `<img key=${index} src="${headimg}" alt="Head Image ${index}" />`
            )
            .join("")}
        </div>
        <div class="row">
          <div class="col-6">
            <h1 style="text-align: left; color: #B49140; font-weight: 700; font-size: 25px;">SALES OFFER:</h1>
            <h1 style="text-align: left; font-weight: 700; font-size: 30px;">${
              formData.title
            }</h1>
            <p style="text-align: left; font-weight: 300; font-size: 14px;">${
              formData.address
            }</p>
          </div>
          <div class="col-6">
            <p>RERA LISTING: ${formData.rera}</p>
          </div>
        </div>
      </section>
      <section class="property-details section">
        <h2 style="text-align: left; color: #B49140;">PROPERTY DETAILS:</h2>
        <div class="row">
          ${[
            { label: "Size", value: `${formData.size} Sqft` },
            { label: "Price", value: `${formData.price} AED` },
            { label: "Status", value: formData.status },
            { label: "Bedroom", value: `${formData.bedrooms} Bedrooms` },
            { label: "Bathrooms", value: `${formData.bathrooms} Bathrooms` },
            { label: "Type", value: formData.ptype },
            { label: "Parkings", value: formData.barea },
            { label: "Plot Area (Sqft)", value: formData.parea },
            { label: "Furnished", value: formData.furnished },
          ]
            .map(
              (detail, index) => `
            <div class="col-4" key=${index}>
              <div class="card">
                <div class="card-header" style="color: #fff; background: #1D1D1F;">${detail.label}</div>
                <p style="margin-left: 5px;">${detail.value}</p>
              </div>
            </div>
          `
            )
            .join("")}
        </div>
      </section>
    `);

    const paymentplanonly = createPageContent(`
      <section class="payment-plan section">
        <h2 style="text-align: left; color: #B49140;">PAYMENT PLAN:</h2>
        <table class="table">
          <thead style="color: #fff; background: #1D1D1F;">
            <tr>
              <th style="background: #1D1D1F; color: #fff;">Description</th>
              <th style="background: #1D1D1F; color: #fff;">Amount (AED)</th>
            </tr>
          </thead>
          <tbody>
            ${formData.paymentPlan
              .map(
                (item, index) => `
              <tr key=${index}>
                <td>${item.description}</td>
                <td>${item.amount} AED</td>
              </tr>
            `
              )
              .join("")}
          </tbody>
        </table>
      </section>
    `);

    const paymentPlanContent = createPageContent(`
      <section class="amenities section">
        <h2 style="text-align: left; color: #B49140;">AMENITIES:</h2>
        <ul class="amenities-list">
          ${formData.amenities
            .map(
              (amenity, index) => `
            <li key=${index}>
              <img src="https://i.imgur.com/Q7HeEE5.png" alt="Amenity icon" />${amenity}
            </li>
          `
            )
            .join("")}
        </ul>
      </section>
      <section class="map section">
        <h2 style="text-align: left; color: #B49140;">LOCATION:</h2>
        <div id="map" style="width: 100%; height: 370px; text-align: center;">
          <img src="https://i.imgur.com/inzFxaw.png" alt="Map location" style="width: 100%; height: 370px;" />
        </div>
      </section>
    `);

    const galleryContent = createPageContent(`
      <section class="gallery section">
        <h2 style="text-align: left; color: #B49140;">GALLERY:</h2>
        <div class="imagesgallery">
          ${formData.gallery
            .map(
              (image, index) =>
                `<img key=${index} src="${image}" alt="Gallery ${index}" />`
            )
            .join("")}
        </div>
      </section>
    `);

    const layoutPagesContent = formData.layouts
      .map((layout, index) =>
        createPageContent(`
      <section class="layouts section">
        <h2 style="text-align: left; color: #B49140;">LAYOUTS:</h2>
        <div class="imageslayout">
          <div key=${index} class="layout-entry">
            <h3 style="color: rgb(180, 145, 64); text-align: left;">${layout.title}</h3>
            <p>${layout.description}</p>
            <img src="${layout.image}" alt="Layout ${index}" />
          </div>
        </div>
      </section>
    `)
      )
      .join("");

    const agentInfoContent = createPageContent(`
      <section class="info-card section">
        <h2>Agent Information</h2>
        <div class="card">
          <div class="row card-body">
            <div class="col-4">
              <img class="agent-image" src="${formData.agentImage}" alt="Agent" />
            </div>
            <div class="col-8">
              <h4 class="card-title">${formData.agentName}</h4>
              <p class="card-text">${formData.agentPosition}</p>
              <p class="card-text">${formData.agentPhone}</p>
              <p class="card-text">${formData.agentEmail}</p>
            </div>
          </div>
        </div>
      </section>
    `);

    const content =
      firstPageContent +
      paymentplanonly +
      paymentPlanContent +
      galleryContent +
      layoutPagesContent +
      agentInfoContent;

    const element = document.createElement("div");
    element.innerHTML = content;

    document.body.appendChild(element);

    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .finally(() => {
        document.body.removeChild(element);
      });
  };

  return (
    <div className="offer-details">
      <button onClick={exportAsPDF} className="btn btn-primary">
        Export as PDF
      </button>
      <br />
      <div className="space"></div>
      <div id="offer-details-content">
        <header
          className="header"
          style={{ background: "linear-gradient(#534936, #000000)" }}
        >
          <h1 style={{ textAlign: "left" }}>
            <img
              src="https://i.imgur.com/EPu5Tos.png"
              alt="Logo"
              style={{ height: "50px" }}
            />
          </h1>
        </header>
        <section>
          <div className="images">
            {formData.headimgs.map((headimg, index) => (
              <img key={index} src={headimg} alt={`Head Image ${index}`} />
            ))}
          </div>
          <div className="row">
            <div className="col-6">
              <h1
                style={{
                  textAlign: "left",
                  color: "#B49140",
                  fontWeight: "700",
                  fontSize: "25px",
                }}
              >
                SALES OFFER:
              </h1>
              <h1
                style={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "30px",
                }}
              >
                {formData.title}
              </h1>
              <p
                style={{
                  textAlign: "left",
                  fontWeight: "300",
                  fontSize: "14px",
                }}
              >
                {formData.address}
              </p>
            </div>
            <div className="col-6">
              <p>RERA LISTING: {formData.rera}</p>
            </div>
          </div>
        </section>
        <section className="property-details section">
          <h2 style={{ textAlign: "left", color: "#B49140" }}>
            PROPERTY DETAILS:
          </h2>
          <div className="row">
            {[
              { label: "Size", value: `${formData.size} Sqft` },
              { label: "Price", value: `${formData.price} AED` },
              { label: "Status", value: formData.status },
              { label: "Bedroom", value: `${formData.bedrooms} Bedrooms` },
              { label: "Bathrooms", value: `${formData.bathrooms} Bathrooms` },
              { label: "Type", value: formData.ptype },
              { label: "Parkings", value: formData.barea },
              { label: "Plot Area (Sqft)", value: formData.parea },
              { label: "Furnished", value: formData.furnished },
            ].map((detail, index) => (
              <div className="col-4" key={index}>
                <div className="card">
                  <div
                    className="card-header"
                    style={{ color: "#fff", background: "#1D1D1F" }}
                  >
                    {detail.label}
                  </div>
                  <p>{detail.value}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="payment-plan section">
          <h2 style={{ textAlign: "left", color: "#B49140" }}>PAYMENT PLAN:</h2>
          <table className="table">
            <thead style={{ color: "#fff", background: "#1D1D1F" }}>
              <tr>
                <th style={{ background: "#1D1D1F", color: "#fff" }}>
                  Description
                </th>
                <th style={{ background: "#1D1D1F", color: "#fff" }}>
                  Amount (AED)
                </th>
              </tr>
            </thead>
            <tbody>
              {formData.paymentPlan.map((item, index) => (
                <tr key={index}>
                  <td>{item.description}</td>
                  <td>{item.amount} AED</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <section className="amenities section">
          <h2 style={{ textAlign: "left", color: "#B49140" }}>AMENITIES:</h2>
          <ul className="amenities-list">
            {formData.amenities.map((amenity, index) => (
              <li key={index}>
                <img src="https://i.imgur.com/Q7HeEE5.png" alt="Amenity icon" />
                {amenity}
              </li>
            ))}
          </ul>
        </section>
        <section className="map section">
          <h2 style={{ textAlign: "left", color: "#B49140" }}>LOCATION:</h2>
          <div
            id="map"
            style={{ width: "100%", height: "370px", textAlign: "center" }}
          >
            <iframe
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAEol__fV92fc0HfJ0eEjLR8lqOqfpV5bg&q=${encodeURIComponent(
                formData.locationLink
              )}`}
              width="100%"
              height="370"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            ></iframe>
          </div>
        </section>
        <section className="gallery section">
          <h2 style={{ textAlign: "left", color: "#B49140" }}>GALLERY:</h2>
          <div className="imagesgallery">
            {formData.gallery.map((image, index) => (
              <img key={index} src={image} alt={`Gallery ${index}`} />
            ))}
          </div>
        </section>
        <section className="layouts section">
          <h2 style={{ textAlign: "left", color: "#B49140" }}>LAYOUTS:</h2>
          <div className="imageslayout">
            {formData.layouts.map((layout, index) => (
              <div key={index} className="layout-entry">
                <h3 style={{ color: "rgb(180, 145, 64);" }}>{layout.title}</h3>
                <p>{layout.description}</p>
                <img src={layout.image} alt={`Layout ${index}`} />
              </div>
            ))}
          </div>
        </section>
        <section className="info-card section">
          <h2>Agent Information</h2>
          <div className="card">
            <div className="row card-body">
              <div className="col-4">
                <img
                  className="agent-image"
                  src={formData.agentImage}
                  alt="Agent"
                />
              </div>
              <div className="col-8">
                <h4 className="card-title">{formData.agentName}</h4>
                <p className="card-text">{formData.agentPosition}</p>
                <p className="card-text">{formData.agentPhone}</p>
                <p className="card-text">{formData.agentEmail}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OfferDetails;
